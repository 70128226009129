import axios from "axios";
import { getApiBaseUrl } from "../../config";

const apiPrefixReports = getApiBaseUrl("reports");

export const getProductType = async (product_type_req_body, authtoken) => {
  return await axios.post(
    `${apiPrefixReports}/api/v1/svc-smart-tag/product-type`,
    product_type_req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};

export const getBrandsFormsAndSize = async (req_body, authtoken) => {
  return await axios.post(
    `${apiPrefixReports}/api/v1/svc-smart-tag/charts-barcode-details`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};

export const getParticipantsList = async (req_body, authtoken) => {
  return await axios.post(
    `${apiPrefixReports}/api/v1/svc-smart-tag/charts-participants`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};

export const getSegmentsList = async (req_body, authtoken) => {
  return await axios.post(
    `${apiPrefixReports}/api/v1/svc-smart-tag/participants-segments`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};

export const getTotalAverageChart = async (req_body, authtoken) => {
  return await axios.post(
    `${apiPrefixReports}/api/v1/svc-smart-tag/charts-total-average`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};

export const getDailyAverageChart = async (req_body, authtoken) => {
  return await axios.post(
    `${apiPrefixReports}/api/v1/svc-smart-tag/charts-daily-average`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};

export const getWeeklyAverageChart = async (req_body, authtoken) => {
  return await axios.post(
    `${apiPrefixReports}/api/v1/svc-smart-tag/charts-weekly-average`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};

export const getDayAverageChart = async (req_body, authtoken) => {
  return await axios.post(
    `${apiPrefixReports}/api/v1/svc-smart-tag/charts-day-average`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};
