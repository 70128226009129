import React from "react";
import { Skeleton } from "primereact/skeleton";

const TableTdSkeleton = () => {
  return (
    <td colSpan="1" className="text-center" style={{ width: "100%" }}>
      <Skeleton className="mb-2"></Skeleton>
    </td>
  );
};

export default TableTdSkeleton;
