import React from "react";
import { Divider } from "primereact/divider";
import Icon from "@mdi/react";
import {
  mdiCalendarMultiple,
  mdiLedOn,
  mdiClockTimeFourOutline,
  mdiMagnifyPlus,
} from "@mdi/js";

const IdentifyWhitespace = () => {
  const data = [
    {
      title: "PERFORMANCE ON TOP 3 CATEGORY OCCASIONS",
      icon: mdiLedOn,
      rows: [
        { value: "48%", label: "ROUTINE LOADS", color: "text-success" },
        { value: "25%", label: "GENTLE LOADS", color: "text-danger" },
        { value: "18%", label: "LARGE/BULKY LOAD", color: "text-success" },
      ],
    },
    {
      title: "PERFORMANCE ON TOP 3 CATEGORY NEEDS",
      icon: mdiMagnifyPlus,
      rows: [
        {
          value: "41%",
          label: "IT EFFECTIVE AT REMOVING STAINS",
          color: "text-success",
        },
        {
          value: "48%",
          label: "IT EFFECTIVE AT REMOVING ODORS",
          color: "text-success",
        },
        {
          value: "39%",
          label: "IT IS EASY/ CONVENIENT TO USE",
          color: "text-secondary",
        },
      ],
    },
    {
      title: "TIME OF DAY",
      icon: mdiClockTimeFourOutline,
      rows: [
        { value: "24%", label: "EARLY MORNING 6-9AM", color: "text-success" },
        {
          value: "25%",
          label: "DAY TIME 9AM - 3:30PM",
          color: "text-secondary",
        },
        {
          value: "21%",
          label: "EARLY EVENING 3:30 - 7:30PM",
          color: "text-secondary",
        },
        { value: "28%", label: "EVENING 7:30 - 11:30PM", color: "text-danger" },
        {
          value: "2%",
          label: "OVERNIGHT 11:30PM - 6AM",
          color: "text-secondary",
        },
      ],
    },
    {
      title: "DAY OF WEEK",
      icon: mdiCalendarMultiple,
      rows: [
        { value: "24%", label: "WEEKEND (S-S)", color: "text-success" },
        { value: "76%", label: "WEEKDAY (M-F)", color: "text-success" },
      ],
    },
  ];

  return (
    <>
      {/* <Divider /> */}
      <div className="mt-4" style={{ maxWidth: "900px" }}>
        <div className="mb-4">
          <h5 className="fw-bold mb-1 text-start text_dark">
            IDENTIFY WHITESPACE
          </h5>
          <p className="text-uppercase mb-0 text-start fw-bold fs-12 text-light-gray">
            Uncover if your brands fall short on any of the top category needs
            AND/OR occasions to inspire innovation opportunities
          </p>
          <p className="text-uppercase mb-0 text-start fw-bold fs-12 py-3 text-light-gray">
            % of uses
          </p>
        </div>

        <div className="table-responsive">
          <table
            className="table align-middle"
            style={{
              tableLayout: "auto",
              width: "100%",
              borderCollapse: "unset",
            }}
          >
            <tbody>
              {data.map((section, index) => (
                <tr key={index}>
                  {/* Section Title and Icon */}
                  <td
                    className="fw-bold text-start align-top"
                    style={{
                      verticalAlign: "top",
                      minWidth: "200px",
                      wordBreak: "break-word",
                      overflowWrap: "break-word",
                    }}
                  >
                    <div
                      className="d-flex align-items-start"
                      style={{
                        minWidth: "255px",
                      }}
                    >
                      <Icon
                        path={section.icon}
                        size={2}
                        color="#BDBDBD"
                        className="text-primary me-3 flex-shrink-0 py-1"
                        style={{
                          verticalAlign: "top",
                        }}
                      />
                      <div
                        className="py-1 text_dark"
                        style={{
                          fontSize: "14px",
                          color: "rgb(73, 80, 87)",
                          lineHeight: "1.4",
                        }}
                      >
                        {section.title}
                      </div>
                    </div>
                  </td>

                  {/* Section Rows (Columns) */}
                  {section.rows.map((row, rowIndex) => (
                    <td
                      key={rowIndex}
                      className="text-center"
                      style={{ padding: "5px 2px 5px 2px" }}
                    >
                      <div
                        className="border rounded py-2"
                        style={{
                          borderColor: "#e0e0e0",
                          height: "80px",
                          width: "93px",
                          fontSize: "12px",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        <h5 className={`fw-bold mb-1 ${row.color}`}>
                          {row.value}
                        </h5>
                        <p
                          className="mb-0 small text-light-gray"
                          style={{
                            lineHeight: "1.2",
                            textOverflow: "ellipsis",
                            padding: "0px 5px 0px 5px",
                          }}
                        >
                          {row.label}
                        </p>
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default IdentifyWhitespace;
