import React from "react";
import { Chart } from "primereact/chart";
import ChartDivider from "../../ChartDivider";
const UsesPerDay = () => {
  const data = {
    labels: ["CATEGORY (N=365)", "TIDE (N=181)", "GAIN (N=120)"],
    datasets: [
      {
        label: "CATEGORY",
        data: [0.8, 0, 0], // Data only for CATEGORY
        backgroundColor: "#FF9800",
      },
      {
        label: "TIDE",
        data: [0, 0.9, 0], // Data only for TIDE
        backgroundColor: "#2196F3",
      },
      {
        label: "GAIN",
        data: [0, 0, 0.7], // Data only for GAIN
        backgroundColor: "#F44336",
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "start",
        labels: {
          padding: 20,
          font: { size: 12, weight: "bold" },
          color: "#6C757D",
        },
      },
      datalabels: {
        color: "#000",
        anchor: "end",
        align: "top",
        font: { weight: "bold", size: 16 },
        formatter: (value) => (value !== 0 ? value : ""),
      },
    },
    scales: {
      x: {
        stacked: true, // Stack the bars horizontally
        ticks: {
          font: { size: 12, weight: "bold" },
        },
      },
      y: {
        beginAtZero: true,
        suggestedMax: 5, // Adjust the maximum value as needed
        ticks: {
          stepSize: 1, // Defines intervals
          callback: (value) => `${value}`,
          font: { size: 12, weight: "bold" },
        },
      },
    },
  };
  return (
    <>
      <div className="mt-4" style={{ maxWidth: "900px" }}>
        <div className="mb-4">
          <h5 className="text-uppercase fw-bold mb-1 text-start">
            Uses per day
          </h5>
          <p
            className="text-uppercase mb-0 text-start fw-bold fs-12 py-3"
            style={{ color: "#6c757d" }}
          >
            Average
          </p>
          <div className="col-12">
            <Chart type="bar" data={data} options={options} />
          </div>
          <ChartDivider />
        </div>
      </div>
    </>
  );
};

export default UsesPerDay;
