import React from "react";
import { Divider } from "primereact/divider";

const OccasionsTable = () => {
  const data = [
    {
      rowHeader: "ROUTINE LAUNDRY DAY",
      category: "40%",
      tide: "53%",
      gain: "34%",
    },
    { rowHeader: "FILL-IN LOAD", category: "26%", tide: "13%", gain: "36%" },
    { rowHeader: "DELICATES", category: "18%", tide: "15%", gain: "12%" },
    {
      rowHeader: "ACCIDENT CLEAN UP",
      category: "16%",
      tide: "19%",
      gain: "18%",
    },
  ];

  return (
    <div className="mt-4" style={{ maxWidth: "900px" }}>
      <div className="mb-4">
        <h5 className="text-uppercase fw-bold mb-1 text-start">Occasions</h5>
        <p
          className="text-uppercase mb-0 text-start fw-bold fs-12 py-3"
          style={{ color: "#6c757d" }}
        >
          % of uses, indexed to category
        </p>
        <div className="col-12 pb-4">
          {/* Table replacing Chart */}
          <table className="table table-striped table-bordered text-center">
            <thead className="thead-light">
              <tr>
                <th style={{ width: "230px" }}></th>
                <th style={{ color: "#6c757d" }} scope="col">
                  CATEGORY
                </th>
                <th style={{ color: "#6c757d" }} scope="col">
                  TIDE
                </th>
                <th style={{ color: "#6c757d" }} scope="col">
                  GAIN
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td
                    className="fw-bold text-start"
                    style={{ color: "#4A5057" }}
                  >
                    {row.rowHeader}
                  </td>
                  <td className="text-success fw-bold">{row.category}</td>
                  <td
                    className={
                      row.tide === "13%" || row.tide === "15%"
                        ? "text-danger fw-bold"
                        : "text-success fw-bold"
                    }
                  >
                    {row.tide}
                  </td>
                  <td
                    className={
                      row.gain === "12%"
                        ? "text-danger fw-bold"
                        : "text-success fw-bold"
                    }
                  >
                    {row.gain}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Divider />
      </div>
    </div>
  );
};

export default OccasionsTable;
