import React, { useState } from "react";
import { Chart } from "primereact/chart";
import { Button } from "primereact/button";
import ChartDivider from "../../ChartDivider";

const DayOfWeek = () => {
  const [activeButton, setActiveButton] = useState("% OF TOTAL");

  const data = {
    labels: ["MON", "TUE", "WED", "THUR", "FRI", "SAT", "SUN"],
    datasets: [
      {
        label: "CATEGORY (N=365)",
        data: [9, 12, 14, 16, 10, 28, 11],
        backgroundColor: "#FF9800",
      },
      {
        label: "TIDE (N=181)",
        data: [15, 10, 17, 15, 6, 24, 13],
        backgroundColor: "#2196F3",
      },
      {
        label: "GAIN (N=120)",
        data: [9, 8, 9, 12, 11, 31, 20],
        backgroundColor: "#F44336",
      },
    ],
  };

  const maxDataValue = Math.max(
    ...data.datasets.flatMap((dataset) => dataset.data),
  );
  const suggestedMax = Math.ceil(maxDataValue / 10) * 10 + 10;

  const options = {
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "start",
        labels: {
          padding: 15,
          font: { size: 12, weight: "bold" },
          color: "#6C757D",
        },
      },
      datalabels: {
        color: "#000",
        anchor: "end",
        align: "top",
        font: { weight: "bold", size: 16 },
        formatter: (value) => (value !== 0 ? value : ""),
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 100,
        title: {
          display: true,
          text: "% OF USES",
          font: { size: 12, weight: "bold" },
        },
        ticks: {
          stepSize: 25, // Defines intervals
          callback: (value) => `${value}`,
          font: { size: 12, weight: "bold" },
        },
      },
      x: {
        ticks: {
          font: { size: 12, weight: "bold" },
        },
      },
    },
  };

  const handleClick = (label) => setActiveButton(label);

  return (
    <div className="mt-4" style={{ maxWidth: "900px" }}>
      <div className="mb-4">
        <h5 className="text-uppercase fw-bold mb-1 text-start">
          <span>Day of Week</span>
          <div
            className="d-flex align-items-center gap-2"
            style={{ float: "right" }}
          >
            <Button
              label="% OF TOTAL"
              className={`px-3 py-2 fw-bold fs-6 ${
                activeButton === "% OF TOTAL"
                  ? "btn-primary text-white"
                  : "btn-light text-dark"
              }`}
              onClick={() => handleClick("% OF TOTAL")}
              style={{
                borderRadius: "10px",
                border: "none",
                height: "25px",
                backgroundColor:
                  activeButton === "% OF TOTAL" ? "#002D42" : "#E0E0E0",
              }}
            />
            <Button
              label="# OF USES"
              className={`px-3 py-2 fw-bold fs-6 ${
                activeButton === "# OF USES"
                  ? "btn-primary text-white"
                  : "btn-light text-dark"
              }`}
              onClick={() => handleClick("# OF USES")}
              style={{
                borderRadius: "10px",
                border: "none",
                height: "25px",
                backgroundColor:
                  activeButton === "# OF USES" ? "#002D42" : "#E0E0E0",
              }}
            />
          </div>
        </h5>
        <p
          className="text-uppercase mb-0 text-start fw-bold fs-12 py-3"
          style={{ color: "#6c757d" }}
        >
          % of uses
        </p>
        <div className="col-12">
          <Chart type="bar" data={data} options={options} />
        </div>
        <ChartDivider />
      </div>
    </div>
  );
};

export default DayOfWeek;
