import React from "react";
import FilterByForDashboard from "../FilterByForDashboard";
import DataAndQuantityType from "./experienceComponents/DataAndQuantityType";
import OverallSatisfaction from "./experienceComponents/OverallSatisfaction";
import RepeatPurchaseIntent from "./experienceComponents/RepeatPurchaseIntent";
import PerformanceVsExpectations from "./experienceComponents/PerformanceVsExpectations";
import ValueForMoney from "./experienceComponents/ValueForMoney";
import Uniqueness from "./experienceComponents/Uniqueness";
import Likes from "./experienceComponents/Likes";
import Dislikes from "./experienceComponents/Dislikes";
const Experience = (props) => {
  return (
    <div className="row">
      {/* Filter By Component */}
      <FilterByForDashboard />
      <div className="col-9" style={{ padding: "0px 5%" }}>
        <DataAndQuantityType />
        <OverallSatisfaction />
        <RepeatPurchaseIntent />
        <PerformanceVsExpectations />
        <ValueForMoney />
        <Uniqueness />
        <Likes />
        <Dislikes />
      </div>
    </div>
  );
};
export default Experience;
