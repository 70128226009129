import React, { useState } from "react";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";

const DataAndQuantityType = () => {
  // State to track the active button
  const [activeButton, setActiveButton] = useState("TOP BOX (%)");

  // Button options
  const buttonOptions = [
    "TOP BOX (%)",
    "TOP TWO BOX (%)",
    "MEAN SCORE (AVG)",
    "FULL DATA",
  ];

  // Handler to update active button
  const handleButtonClick = (option) => {
    setActiveButton(option);
  };

  return (
    <>
      <div className="d-flex py-4">
        {buttonOptions.map((option) => (
          <Button
            key={option}
            className={`fw-bold btn me-2 ${
              activeButton === option ? "active-button br-c" : "light-bg br-c"
            }`}
            onClick={() => handleButtonClick(option)}
            label={option}
          />
        ))}
      </div>
      <Divider />
    </>
  );
};

export default DataAndQuantityType;
