import React from "react";
import FilterByForDashboard from "../FilterByForDashboard";
import UsesPerDay from "./frequencyComponents/UsesPerDay";
import UsesPerWeek from "./frequencyComponents/UsesPerWeek";
import TimeOfDay from "./frequencyComponents/TimeOfDay";
import DayOfWeek from "./frequencyComponents/DayOfWeek";
import WeekPart from "./frequencyComponents/WeekPart";

const Frequency = () => {
  return (
    <div className="row">
      {/* Filter By Component */}
      <FilterByForDashboard />
      <div className="col-9" style={{ padding: "0px 3%" }}>
        <UsesPerDay />
        <UsesPerWeek />
        <TimeOfDay />
        <DayOfWeek />
        <WeekPart />
      </div>
    </div>
  );
};

export default Frequency;
