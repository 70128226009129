import React, { useState } from "react";
import { Card } from "primereact/card";
import { TabView, TabPanel } from "primereact/tabview";
import "../../assets/css/dashboard.css"; // Ensure your CSS file is correctly imported
import Usage from "../../components/dashboardTabs/usage/Usage";
import Occasions from "../../components/dashboardTabs/occasions/Occasions";
import { useGlobalContext } from "../../context/GlobalContext";
import Experience from "../../components/dashboardTabs/experience/Experience";
import BrandScorecard from "../../components/dashboardTabs/brandScorecard/BrandScorecard";
import Frequency from "../../components/dashboardTabs/frequency/Frequency";
import Amount from "../../components/dashboardTabs/amount/Amount";
import Needs from "../../components/dashboardTabs/needs/Needs";
const Dashboard = () => {
  const { device, deviceCode, category } = useGlobalContext();
  return (
    <div className="container-fluid page-wrapper">
      <div className="row">
        <div className="col-12 col-md-12">
          <Card>
            <TabView>
              <TabPanel header="BRAND SCORECARD" className="tabStyle">
                <BrandScorecard
                  device={device}
                  deviceCode={deviceCode}
                  category={category}
                />
              </TabPanel>
              <TabPanel header="FREQUENCY">
                <Frequency />
              </TabPanel>
              <TabPanel header="AMOUNT">
                <Amount />
              </TabPanel>
              <TabPanel header="OCCASIONS">
                <Occasions />
              </TabPanel>
              <TabPanel header="NEEDS">
                <Needs />
              </TabPanel>
              <TabPanel header="EXPERIENCE">
                <Experience />
              </TabPanel>
              {/* <TabPanel header="LOYALTY">
                <UserProfile />
              </TabPanel> */}
              <TabPanel header="USER SUMMARY">
                <Usage />
              </TabPanel>
            </TabView>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
