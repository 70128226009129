import React from "react";
import { Chart } from "primereact/chart";
import TableTdSkeleton from "../../../components/Skeletons/TableTdSkeleton";
import ChartSkeleton from "../../../components/Skeletons/ChartSkeleton";
import TableThSkeleton from "../../../components/Skeletons/TableThSkeleton";

const DailyAverage = React.memo(function DailyAverage(props) {
  debugger;
  const dailyAverageOptions = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 10, // Ensures a difference of 10 units between y-axis ticks
        },
      },
    },
  };

  return (
    <div className="col-12">
      <strong className="fs-14 float-start pb-4">
        # OF USES PER DAY OF WEEK - DAILY AVERAGE
      </strong>
      {props.dailyAverageGraph?.datasets?.length > 0 ? (
        <Chart
          type="bar"
          data={props.dailyAverageGraph}
          options={dailyAverageOptions}
        />
      ) : (
        <ChartSkeleton />
      )}
      <div className="my-table data-table">
        <div className="table-responsive">
          <table className="table text-start">
            <thead>
              <tr className="table-first-row-border">
                <th></th>
                {props.dailyAverageGraph?.labels?.length > 0 ? (
                  props.dailyAverageGraph.labels.map((label) => (
                    <th key={label} style={{ color: "#B1B1B1" }}>
                      {label}
                    </th>
                  ))
                ) : (
                  <TableThSkeleton />
                )}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong style={{ color: "#949494" }}>AVERAGE</strong>
                </td>
                {props.dailyAverageGraph?.datasets?.[0]?.data?.length > 0 ? (
                  props.dailyAverageGraph.datasets[0].data.map(
                    (data, index) => (
                      <td
                        className="text-center"
                        key={index}
                        style={{ color: "#949494" }}
                      >
                        {data}
                      </td>
                    ),
                  )
                ) : (
                  <TableTdSkeleton />
                )}
              </tr>
              <tr>
                <td>
                  <strong style={{ color: "#949494" }}>
                    # of Participants
                  </strong>
                </td>
                {props.dailyAverageGraph?.datasets?.[0]?.participant?.length >
                0 ? (
                  props.dailyAverageGraph.datasets[0].participant.map(
                    (participant, index) => (
                      <td
                        className="text-center"
                        key={index}
                        style={{ color: "#949494" }}
                      >
                        {participant}
                      </td>
                    ),
                  )
                ) : (
                  <TableTdSkeleton />
                )}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
export default DailyAverage;
