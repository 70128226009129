import React from "react";

const ChartDivider = (props) => {
  debugger;
  return (
    <>
      <hr
        style={{
          marginTop:
            props.extramargin !== "" ? `${props.extramargin}rem` : "1.5rem",
        }}
      />
      <hr style={{ marginTop: "3rem" }} />
    </>
  );
};
export default ChartDivider;
