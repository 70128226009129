import React from "react";
import FilterByForDashboard from "../FilterByForDashboard";
import IndexIndecator from "../../IndexIndicator/IndexIndecator";
import QuantityIndecator from "./brandScorecardComponents/QuantityIndecator";
import GrowYourBrand from "./brandScorecardComponents/GrowYourBrand";
import IdentifyWhitespace from "./brandScorecardComponents/IdentifyWhitespace";
import StrengthenCompetitiveEdge from "./brandScorecardComponents/StrengthenCompetitiveEdge";
import RefineYourTarget from "./brandScorecardComponents/RefineYourTarget";

const BrandScorecard = () => {
  return (
    <div className="row">
      {/* Filter By Component */}
      <FilterByForDashboard />
      <div className="col-9" style={{ padding: "0px 3%" }}>
        <IndexIndecator />
        <QuantityIndecator />
        <GrowYourBrand />
        <IdentifyWhitespace />
        <StrengthenCompetitiveEdge />
        <RefineYourTarget />
      </div>
    </div>
  );
};

export default BrandScorecard;
