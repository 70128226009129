import React from "react";
import { Chart } from "primereact/chart";
import ChartDivider from "../../ChartDivider";

const OccasionsChart = () => {
  const data = {
    labels: [
      "ROUTINE LAUNDRY DAY",
      "FILL-IN LOAD",
      "DELICATES",
      "ACCIDENT CLEAN UP",
    ],
    datasets: [
      {
        label: "CATEGORY",
        backgroundColor: "#FF9800",
        data: [65, 59, 75, 55], // Match length with labels
      },
      {
        label: "TIDE",
        backgroundColor: "#2196F3",
        data: [28, 48, 40, 30], // Match length with labels
      },
      {
        label: "GAIN",
        backgroundColor: "#F44336",
        data: [20, 30, 35, 25], // Match length with labels
      },
    ],
  };

  const options = {
    indexAxis: "y", // Horizontal bar chart
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "start",
        labels: {
          padding: 15,
          font: { size: 12, weight: "bold" },
          color: "#6C757D",
        },
      },
      datalabels: {
        color: "#000",
        anchor: "end",
        align: "right",
        font: { weight: "bold", size: 16 },
        formatter: (value) => (value !== 0 ? value : ""),
      },
    },
    scales: {
      x: {
        stacked: false, // Stack the bars horizontally
        suggestedMax: 100,
        ticks: {
          stepSize: 25, // Defines intervals
          font: { size: 12, weight: "bold" },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          font: { size: 12, weight: "bold" },
        },
      },
    },
  };

  return (
    <div className="mt-4" style={{ maxWidth: "900px" }}>
      <div className="mb-4">
        <h5 className="text-uppercase fw-bold mb-1 text-start">Occasions</h5>
        <p
          className="text-uppercase mb-0 text-start fw-bold fs-12 py-3"
          style={{ color: "#6c757d" }}
        >
          % of uses
        </p>
        <div className="col-12">
          <Chart type="bar" data={data} options={options} />
        </div>
        <ChartDivider />
      </div>
    </div>
  );
};

export default OccasionsChart;
