import React from "react";
import FilterByForDashboard from "../FilterByForDashboard";
import NeedsChart from "./needsComponents/NeedsCharts";
import NeedsTable from "./needsComponents/NeedsTable";
import IndexIndecator from "../../IndexIndicator/IndexIndecator";
const Needs = () => {
  return (
    <div className="row">
      {/* Filter By Component */}
      <FilterByForDashboard />
      <div className="col-9" style={{ padding: "0px 5%" }}>
        <NeedsChart />
        <NeedsTable />
        <IndexIndecator />
      </div>
    </div>
  );
};
export default Needs;
