import React, { useState } from "react";
import { Divider } from "primereact/divider";
import { Tag } from "primereact/tag";

const QuantityIndecator = () => {
  const [activeTag, setActiveTag] = useState("GM"); // Default active tag

  const handleTagClick = (tag) => {
    setActiveTag(tag);
  };

  return (
    <>
      <div
        className="row align-items-center fw-600"
        style={{ padding: "12px 0px" }}
      >
        <div
          className="col-auto d-flex align-items-center"
          style={{ padding: "6px" }}
        >
          <Tag
            value="GM"
            className={`px-3 py-2 fw-bold fs-6 ${
              activeTag === "GM"
                ? "btn-primary text-white"
                : "btn-light text-dark"
            }`}
            style={{
              borderRadius: "10px",
              border: "none",
              height: "25px",
              width: "40px",
              backgroundColor: activeTag === "GM" ? "#002D42" : "#E0E0E0",
              cursor: "pointer",
            }}
            onClick={() => handleTagClick("GM")}
          />
        </div>
        <div
          className="col-auto d-flex align-items-center"
          style={{ padding: "0px" }}
        >
          <Tag
            value="OZ"
            className={`px-3 py-2 fw-bold fs-6 ${
              activeTag === "OZ"
                ? "btn-primary text-white"
                : "btn-light text-dark"
            }`}
            style={{
              borderRadius: "10px",
              border: "none",
              height: "25px",
              width: "40px",
              backgroundColor: activeTag === "OZ" ? "#002D42" : "#E0E0E0",
              cursor: "pointer",
            }}
            onClick={() => handleTagClick("OZ")}
          />
        </div>
      </div>
      <Divider type="solid" />
    </>
  );
};

export default QuantityIndecator;
