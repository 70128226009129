import React, { useState } from "react";
import { Chart } from "primereact/chart";
import { Button } from "primereact/button";
import ChartDivider from "../../ChartDivider";
import { Divider } from "primereact/divider";
const WeekPart = () => {
  const [activeButton, setActiveButton] = useState("% OF TOTAL");
  const data = {
    labels: ["WEEKDAY (M-F)", "WEEKEND (S-S)"],
    datasets: [
      {
        label: "CATEGORY (N=365)",
        backgroundColor: "#FF9800", // Corrected color definition
        data: [51, 49],
      },
      {
        label: "TIDE (N=181)",
        backgroundColor: "#2196F3", // Corrected color definition
        data: [73, 27],
      },
      {
        label: "GAIN (N=120)",
        backgroundColor: "#F44336", // Corrected color definition
        data: [25, 75],
      },
    ],
  };
  const maxDataValue = Math.max(
    ...data.datasets.flatMap((dataset) => dataset.data),
  );
  const suggestedMax = Math.ceil(maxDataValue / 10) * 10 + 10; // Rounds up to nearest 10 and adds extra 10%
  const options = {
    // indexAxis: "y", // Horizontal bar chart
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "start",
        labels: {
          padding: 15,
          font: { size: 12, weight: "bold" },
          color: "#6C757D",
        },
      },
      datalabels: {
        color: "#000",
        anchor: "end",
        align: "top",
        font: { weight: "bold", size: 16 },
        formatter: (value) => (value !== 0 ? value : ""),
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: suggestedMax,
        title: {
          display: true,
          text: "% OF USES",
          font: { size: 12, weight: "bold" },
        },
        ticks: {
          callback: (value) => `${value}`, // Append '%' to the Y-axis tick values
          font: { size: 12, weight: "bold" },
        },
      },
      x: {
        ticks: {
          font: { size: 12, weight: "bold" },
        },
      },
    },
  };

  const handleClick = (label) => setActiveButton(label);

  return (
    <>
      <div className="mt-4" style={{ maxWidth: "900px" }}>
        <div className="mb-4">
          <h5 className="text-uppercase fw-bold mb-1 text-start">
            <span>WEEK PART</span>
            <div
              className="d-flex align-items-center gap-2"
              style={{ float: "right" }}
            >
              <Button
                label="% OF TOTAL"
                className={`px-3 py-2 fw-bold fs-6 ${
                  activeButton === "% OF TOTAL"
                    ? "btn-primary text-white"
                    : "btn-light text-dark"
                }`}
                onClick={() => handleClick("% OF TOTAL")}
                style={{
                  borderRadius: "10px",
                  border: "none",
                  height: "25px",
                  backgroundColor:
                    activeButton === "% OF TOTAL" ? "#002D42" : "#E0E0E0",
                }}
              />
              <Button
                label="# OF USES"
                className={`px-3 py-2 fw-bold fs-6 ${
                  activeButton === "# OF USES"
                    ? "btn-primary text-white"
                    : "btn-light text-dark"
                }`}
                onClick={() => handleClick("# OF USES")}
                style={{
                  borderRadius: "10px",
                  border: "none",
                  height: "25px",
                  backgroundColor:
                    activeButton === "# OF USES" ? "#002D42" : "#E0E0E0",
                }}
              />
            </div>
          </h5>
          <p
            className="text-uppercase mb-0 text-start fw-bold fs-12 py-3"
            style={{ color: "#6c757d" }}
          >
            % of uses
          </p>
          <div className="col-12 pb-5">
            <Chart type="bar" data={data} options={options} />
          </div>
          <Divider />
        </div>
      </div>
    </>
  );
};

export default WeekPart;
