import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import "../../assets/css/dashboard.css"; // Ensure your CSS file is correctly imported
import FilterBy from "../../components/reports/FilterBy";
import TotalAverage from "./reportsComponents/TotalAverage";
import DailyAverage from "./reportsComponents/DailyAverage";
import WeeklyAverage from "./reportsComponents/WeeklyAverage";
import TimeOfDayAverage from "./reportsComponents/TimeOfDayAverage";
import { useGlobalContext } from "../../context/GlobalContext";
import {
  getDailyAverageChart,
  getDayAverageChart,
  getTotalAverageChart,
  getWeeklyAverageChart,
} from "../../services/reports/reports";
import { PROJECT_ID } from "../../utils/constants";
import { useSelector } from "react-redux";
const Reports = (props) => {
  const { device, deviceCode, category, product_id } = useGlobalContext();
  const [activeButton, setActiveButton] = useState("how_often");
  const [productType, setProductType] = useState("");
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedForms, setSelectedForms] = useState([]);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedSegment, setSelectedSegments] = useState([]);
  const [activeOption, setActiveOption] = useState("FILTERBY");
  const [applyFilter, setApplyFilter] = useState(false);
  const [selectedOption, setSelectedOption] = useState("day");
  const [totalAverageGraph, setTotalAverageGraph] = useState("");
  const [dailyAverageGraph, setDailyAverageGraph] = useState("");
  const [weeklyAverageGraph, setWeeklyAverageGraph] = useState("");
  const [dayAverageGraph, setDayAverageGraph] = useState("");

  const { token } = useSelector((state) => state.auth);
  const handleButtonClick = (buttonLabel) => {
    setActiveButton(buttonLabel);
    if (buttonLabel === "how_often") {
      setSelectedOption("day");
    } else {
      setSelectedOption("use");
    }
  };
  const fetchTotalAverageChart = () => {
    // setLoading(true);
    const requestBody = {
      chart_type: activeButton,
      compare_age: "",
      compare_brand:
        activeOption === "COMPAREBY" ? selectedBrands.join("|") : "",
      compare_form: activeOption === "COMPAREBY" ? selectedForms.join("|") : "",
      compare_gender:
        activeOption === "COMPAREBY" ? selectedGenders.join("|") : "",
      compare_participant:
        activeOption === "COMPAREBY" ? selectedParticipants.join(",") : "",
      compare_product_category: "",
      compare_product_type: activeOption === "COMPAREBY" ? productType : "",
      compare_segment:
        activeOption === "COMPAREBY" ? selectedSegment.join(",") : "",
      filter_age: "",
      filter_brand: activeOption === "FILTERBY" ? selectedBrands.join("|") : "",
      filter_form: activeOption === "FILTERBY" ? selectedForms.join("|") : "",
      filter_gender:
        activeOption === "FILTERBY" ? selectedGenders.join("|") : "",
      filter_participant:
        activeOption === "FILTERBY" ? selectedParticipants.join(",") : "",
      filter_product_category: parseInt(product_id),
      filter_product_type: activeOption === "FILTERBY" ? productType : "",
      filter_segment:
        activeOption === "FILTERBY" ? selectedSegment.join(",") : "",
      graph_type: "total_average",
      how_long_use_per: activeButton !== "how_often" ? selectedOption : "",
      how_often_use_per: activeButton === "how_often" ? selectedOption : "",
      project_id: parseInt(PROJECT_ID),
      section_type: "filter",
    };
    // getTotalAverageChart(requestBody, token).then((response) => {
    //   setTotalAverageGraph(response.data.graphs);
    // });
    Promise.all([
      getTotalAverageChart(requestBody, token),
      getDailyAverageChart(requestBody, token),
      getWeeklyAverageChart(requestBody, token),
      getDayAverageChart(requestBody, token),
    ])
      .then(
        ([
          totalAverageResponse,
          dailyAverageResponse,
          weeklyAverageResponse,
          dayAverageResponse,
        ]) => {
          setTotalAverageGraph(totalAverageResponse.data.graphs);
          setDailyAverageGraph(dailyAverageResponse.data.graphs);
          setWeeklyAverageGraph(weeklyAverageResponse.data.graphs);
          setDayAverageGraph(dayAverageResponse.data.graphs);
        },
      )
      .catch((error) => {
        console.error("Error fetching charts:", error);
        // Handle errors as needed
      });
  };

  useEffect(() => {
    if (productType !== "") {
      fetchTotalAverageChart();
    }
  }, [props, applyFilter, productType]);

  return (
    <div className="container-fluid page-wrapper">
      <div className="row">
        <div className="col-12 col-md-12">
          <Card>
            <TabView>
              <TabPanel header="SENSOR CHARTS" className="tabStyle">
                <div className="d-flex align-items-center justify-content-center managed-row flex-nowrap">
                  <Button
                    className={`p-button-text me-2 me-md-3 ${activeButton === "how_often" ? "active-button" : "inactive-button"} br-c`}
                    outlined
                    onClick={() => handleButtonClick("how_often")}
                    label="HOW OFTEN"
                  />
                  {props.deviceCode === 1 ? (
                    <Button
                      className={`p-button-text me-2 me-md-3 ${activeButton === "how_much" ? "active-button" : "inactive-button"} br-c`}
                      outlined
                      onClick={() => handleButtonClick("how_much")}
                      label="HOW MUCH"
                    />
                  ) : (
                    <Button
                      className={`p-button-text me-2 me-md-3 ${activeButton === "how_long" ? "active-button" : "inactive-button"} br-c`}
                      outlined
                      onClick={() => handleButtonClick("how_long")}
                      label="HOW LONG"
                    />
                  )}
                  {device},{deviceCode},{category},{product_id}
                </div>
                <div className="row" style={{ borderTop: "1px solid #dee2e6" }}>
                  {/* Filter By Component */}
                  <FilterBy
                    device={device}
                    deviceCode={deviceCode}
                    category={category}
                    product_id={product_id}
                    selectedBrands={selectedBrands}
                    setSelectedBrands={setSelectedBrands}
                    selectedGenders={selectedGenders}
                    setSelectedGenders={setSelectedGenders}
                    selectedForms={selectedForms}
                    setSelectedForms={setSelectedForms}
                    selectedParticipants={selectedParticipants}
                    setSelectedParticipants={setSelectedParticipants}
                    selectedSizes={selectedSizes}
                    setSelectedSizes={setSelectedSizes}
                    selectedSegment={selectedSegment}
                    setSelectedSegments={setSelectedSegments}
                    productType={productType}
                    setProductType={setProductType}
                    activeOption={activeOption}
                    setActiveOption={setActiveOption}
                    applyFilter={applyFilter}
                    setApplyFilter={setApplyFilter}
                  />
                  <div className="col-9" style={{ padding: "0px 5%" }}>
                    <TotalAverage
                      device={device}
                      deviceCode={deviceCode}
                      category={category}
                      product_id={product_id}
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                      activeButton={activeButton}
                      totalAverageGraph={totalAverageGraph}
                    />
                    <hr style={{ margin: "2rem 0" }} />
                    <DailyAverage
                      device={device}
                      deviceCode={deviceCode}
                      category={category}
                      product_id={product_id}
                      activeButton={activeButton}
                      dailyAverageGraph={dailyAverageGraph}
                    />
                    <hr style={{ margin: "2rem 0" }} />
                    <WeeklyAverage
                      device={device}
                      deviceCode={deviceCode}
                      category={category}
                      product_id={product_id}
                    />
                    <hr style={{ margin: "2rem 0" }} />
                    <TimeOfDayAverage
                      device={device}
                      deviceCode={deviceCode}
                      category={category}
                      product_id={product_id}
                    />
                  </div>
                </div>
              </TabPanel>
            </TabView>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Reports;
