import React from "react";
import { Chart } from "primereact/chart";
import ChartDivider from "../../ChartDivider";

const OverallSatisfaction = () => {
  const data = {
    labels: [
      "VERY SATISFIED",
      "SOMEWHAT SATISFIED",
      "NEUTRAL",
      "SOMEWHAT UNSATISFIED",
      "VERY UNSATISFIED",
    ],
    datasets: [
      {
        label: "CATEGORY",
        backgroundColor: "#FF9800",
        data: [24, 10, 15, 31, 20], // Match length with labels
      },
      {
        label: "TIDE",
        backgroundColor: "#2196F3",
        data: [40, 12, 20, 10, 16], // Match length with labels
      },
      {
        label: "GAIN",
        backgroundColor: "#F44336",
        data: [20, 9, 15, 26, 30], // Match length with labels
      },
    ],
  };
  const maxDataValue = Math.max(
    ...data.datasets.flatMap((dataset) => dataset.data),
  );
  const suggestedMax = Math.ceil(maxDataValue / 10) * 10 + 10; // Rounds up to nearest 10 and adds extra 10%

  const options = {
    indexAxis: "y", // Horizontal bar chart
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "start",
        labels: {
          padding: 15,
          font: { size: 12, weight: "bold" },
          color: "#6C757D",
        },
      },
      datalabels: {
        color: "#000",
        anchor: "end",
        align: "right",
        font: { weight: "bold", size: 16 },
        formatter: (value) => (value !== 0 ? value : ""),
      },
    },
    scales: {
      x: {
        stacked: false, // Stack the bars horizontally
        suggestedMax: suggestedMax,
        ticks: {
          stepSize: 10, // Defines intervals
          font: { size: 12, weight: "bold" },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          font: { size: 12, weight: "bold" },
        },
      },
    },
  };

  return (
    <div className="mt-4" style={{ maxWidth: "900px" }}>
      <div className="mb-4">
        <h5 className="text-uppercase fw-bold mb-1 text-start">
          Overall Satisfaction
        </h5>
        <p
          className="text-uppercase mb-0 text-start fw-bold fs-12 py-3"
          style={{ color: "#6c757d" }}
        >
          % top box
        </p>
        <div className="col-12 pb-4">
          <Chart type="bar" data={data} options={options} />
        </div>
        <p
          style={{ width: "65%" }}
          className="ms-5 p-2 fs-12 float-inherit text-start rounded-2 fw-bold border text-secondary"
        >
          HOW SATISFIED ARE YOU WITH [PRODUCT NAME] OVERALL?
        </p>
        <ChartDivider />
      </div>
    </div>
  );
};

export default OverallSatisfaction;
