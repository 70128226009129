import React from "react";
import { Tag } from "primereact/tag";
import { Divider } from "primereact/divider";

const IndexIndecator = () => {
  return (
    <>
      <div
        className="row align-items-center fw-600"
        style={{ padding: "12px 0px" }}
      >
        {/* Column for ">= 110 INDEX" */}
        <div className="col-auto d-flex align-items-center">
          <Tag
            severity="success"
            className="me-2"
            style={{ borderRadius: "25%", width: "20px", height: "16px" }}
          />
          <span className="ms-1 text-success">&gt;= 110 INDEX</span>
        </div>
        {/* Column for "91 - 109 INDEX" */}
        <div className="col-auto d-flex align-items-center">
          <Tag
            className="me-2"
            style={{
              backgroundColor: "#666666",
              borderRadius: "25%",
              width: "20px",
              height: "16px",
            }}
          />
          <span className="ms-1 text-dark-gray">91 - 109 INDEX</span>
        </div>
        {/* Column for "<= 90 INDEX" */}
        <div className="col-auto d-flex align-items-center">
          <Tag
            severity="danger"
            className="me-2"
            style={{
              backgroundColor: "#F44336",
              borderRadius: "25%",
              width: "20px",
              height: "16px",
            }}
          />
          <span className="ms-1 text-red">&lt;= 90 INDEX</span>
        </div>
      </div>
      <Divider type="solid" />
    </>
  );
};

export default IndexIndecator;
