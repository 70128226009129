import React from "react";
import FilterByForDashboard from "../FilterByForDashboard";
import QuantityIndecator from "../brandScorecard/brandScorecardComponents/QuantityIndecator";
import OuncesPerUse from "./amountComponents/OuncesPerUse";
import TimeOfDay from "./amountComponents/TimeOfDay";
import DayOfWeek from "./amountComponents/DayOfWeek";
import WeekPart from "./amountComponents/WeekPart";

const Amount = () => {
  return (
    <div className="row">
      {/* Filter By Component */}
      <FilterByForDashboard />
      <div className="col-9" style={{ padding: "0px 3%" }}>
        <QuantityIndecator />
        <OuncesPerUse />
        <TimeOfDay />
        <DayOfWeek />
        <WeekPart />
      </div>
    </div>
  );
};

export default Amount;
