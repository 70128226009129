import React from "react";
import { Divider } from "primereact/divider";

const StrengthenCompetitiveEdge = () => {
  const data = [
    { title: "SATISFACTION", value: "4.2" },
    { title: "REPEAT PI", value: "4.0" },
    { title: "PERFORMANCE VS. EXPECTATIONS", value: "3.9" },
    { title: "VALUE", value: "3.8" },
    { title: "UNIQUENESS", value: "3.8" },
  ];

  return (
    <>
      <Divider />
      <div className="mt-4" style={{ maxWidth: "900px" }}>
        <div className="mb-4">
          <h5 className="text-uppercase fw-bold mb-1 text-start text_dark">
            Strengthen Competitive Edge
          </h5>
          <p className="text-uppercase mb-0 text-start fw-bold fs-12 text-light-gray">
            Understand your brand's performance to identify ways to improve your
            brand health
          </p>
          <p className="text-uppercase mb-0 text-start fw-bold fs-12 py-3 text-dark-gray">
            Average (5-point scale)
          </p>
        </div>

        {/* Titles Row */}
        <div className="d-flex justify-content-between">
          {data.map((item, index) => (
            <>
              <div key={index} className="text-center" style={{ width: "18%" }}>
                <p className="fw-bold mb-1 fs-12 text-dark-gray">
                  {item.title}
                </p>
              </div>
              <Divider layout="vertical" />
            </>
          ))}
        </div>

        {/* Chips Row */}
        <div className="d-flex justify-content-between align-items-center">
          {data.map((item, index) => (
            <>
              <div key={index} className="text-center" style={{ width: "18%" }}>
                <div
                  className={`rounded-lg font-bold household-chip`}
                  style={{
                    width: "70px",
                    textAlign: "center",
                    margin: "0 auto",
                  }}
                >
                  {item.value}
                </div>
              </div>
              <Divider layout="vertical" />
            </>
          ))}
        </div>

        <div className="py-3">
          <Divider type="solid" />
        </div>
      </div>
    </>
  );
};

export default StrengthenCompetitiveEdge;
