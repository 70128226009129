import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const HouseHoldFrequencyTable = () => {
  const data = [
    {
      type: "HIGH FREQ HOUSEHOLDS",
      values: [1.4, 5.2, 3.0],
      trend: "pi pi-arrow-up",
    },
    {
      type: "MEDIUM FREQ HOUSEHOLDS",
      values: [0.7, 4.1, 1.7],
      trend: "pi pi-equals",
    },
    {
      type: "LOW FREQ HOUSEHOLDS",
      values: [0.3, 3.2, 2.0],
      trend: "pi pi-arrow-down",
    },
  ];

  const trendTemplate = (rowData) => (
    <i
      className={`${rowData.trend} me-2`}
      style={{ color: getTrendColor(rowData.trend) }}
    ></i>
  );

  const getTrendColor = (trend) => {
    return "gray";
  };

  const valueTemplate = (value) => (
    <span
      className={`fw-bold ${value >= 3 ? "text-success" : value < 1 ? "text-danger" : ""}`}
    >
      {value}
    </span>
  );

  const typeTemplate = (type) => (
    <span className="fw-600 fs-14 text-dark-gray" style={{ color: "#808080" }}>
      {type}
    </span>
  );

  return (
    <div className="">
      <style>
        {`
          .table tbody tr:first-child {
              border-bottom: 0px solid transparent !important;
              border-top: 1px solid lightgray;
          }
          .table tbody tr:last-child {
              border-bottom: 1px solid lightgray;
          }
          .p-datatable .p-datatable-tbody > tr > td {
            text-align: left;
            border: 1px solid #e9ecef;
            border-width: 0 0 1px 0;
            padding: 1rem 0rem;
          }
          .p-datatable .p-datatable-tbody > tr:nth-child(even) {
            background-color: #F5F5F5;
          }
        `}
      </style>
      <DataTable
        stripedRows
        value={data}
        className="table table-striped"
        showHeaders={false} // Hides default headers
      >
        <Column
          field="type"
          header=""
          body={(rowData) => (
            <div className="d-flex align-items-center">
              {trendTemplate(rowData)}
              {typeTemplate(rowData.type)}
            </div>
          )}
          style={{ width: "40%" }}
        />
        <Column
          header="PER DAY"
          body={(rowData) => valueTemplate(rowData.values[0])}
          style={{ width: "21.5%", textAlign: "left" }}
        />
        <Column
          header="PER WEEK"
          body={(rowData) => valueTemplate(rowData.values[1])}
          style={{ width: "21.5%", textAlign: "left" }}
        />
        <Column
          header="GRAMS PER USE"
          body={(rowData) => valueTemplate(rowData.values[2])}
          style={{ width: "21.5%", textAlign: "center" }}
        />
      </DataTable>
    </div>
  );
};

export default HouseHoldFrequencyTable;
