import React from "react";
import FilterByForDashboard from "../FilterByForDashboard";
import OccasionsChart from "./occasionsComponents/OccasionsChart";
import TopNeedsCategory from "../needs/needsComponents/NeedsCharts";
import OccasionsTable from "./occasionsComponents/OccasionsTable";
import IndexIndecator from "../../IndexIndicator/IndexIndecator";

const Occasions = () => {
  return (
    <div className="row">
      {/* Filter By Component */}
      <FilterByForDashboard />
      <div className="col-9" style={{ padding: "0px 3%" }}>
        <OccasionsChart />
        <OccasionsTable />
        <IndexIndecator />
      </div>
    </div>
  );
};

export default Occasions;
