import React, { useState } from "react";
import { Chart } from "primereact/chart";
import ChartDivider from "../../ChartDivider";
import { Button } from "primereact/button";
const TimeOfDay = () => {
  const data = {
    labels: [
      "12AM",
      "2AM",
      "4AM",
      "6AM",
      "8AM",
      "10AM",
      "12PM",
      "2PM",
      "4PM",
      "6PM",
      "8PM",
      "10PM",
    ],
    datasets: [
      {
        label: "CATEGORY (N=365)",
        data: [0, 0, 0, 10, 40, 50, 30, 20, 30, 40, 20, 10],
        borderColor: "#FF9800",
        backgroundColor: "#FF9800",
        tension: 0.4,
      },
      {
        label: "TIDE (N=181)",
        data: [0, 0, 0, 20, 50, 60, 40, 30, 50, 60, 30, 0],
        borderColor: "#2196F3",
        backgroundColor: "#2196F3",
        tension: 0.4,
      },
      {
        label: "GAIN (N=120)",
        data: [0, 0, 0, 15, 45, 55, 35, 25, 45, 55, 25, 5],
        borderColor: "#F44336",
        backgroundColor: "#F44336",
        tension: 0.4,
      },
    ],
  };

  const maxDataValue = Math.max(
    ...data.datasets.flatMap((dataset) => dataset.data),
  );
  const suggestedMax = Math.ceil(maxDataValue / 10) * 10 + 10; // Rounds up to nearest 10 and adds extra 10%

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "start",
        labels: {
          padding: 15,
          font: { size: 12, weight: "bold" },
          color: "#6C757D",
        },
      },
      datalabels: false,
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: suggestedMax,
        title: {
          display: true,
          text: "% OF USES",
          font: { size: 12, weight: "bold" },
        },
        ticks: {
          callback: (value) => `${value}%`, // Append '%' to the Y-axis tick values
          font: { size: 12, weight: "bold" },
        },
      },
      x: {
        ticks: {
          font: { size: 12, weight: "bold" },
        },
      },
    },
  };

  const ToggleButtons = () => {
    const [activeButton, setActiveButton] = useState("% OF TOTAL");

    const handleClick = (label) => {
      setActiveButton(label);
    };

    return (
      <div
        className="d-flex align-items-center gap-2"
        style={{ float: "right" }}
      >
        <Button
          label="% OF TOTAL"
          className={`px-3 py-2 fw-bold fs-6 ${
            activeButton === "% OF TOTAL"
              ? "btn-primary text-white"
              : "btn-light text-dark"
          }`}
          onClick={() => handleClick("% OF TOTAL")}
          style={{
            borderRadius: "10px",
            border: "none",
            height: "25px",
            backgroundColor:
              activeButton === "% OF TOTAL" ? "#002D42" : "#E0E0E0",
          }}
        />
        <Button
          label="# OF USES"
          className={`px-3 py-2 fw-bold fs-6 ${
            activeButton === "# OF USES"
              ? "btn-primary text-white"
              : "btn-light text-dark"
          }`}
          onClick={() => handleClick("# OF USES")}
          style={{
            borderRadius: "10px",
            border: "none",
            height: "25px",
            backgroundColor:
              activeButton === "# OF USES" ? "#002D42" : "#E0E0E0",
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div className="mt-4" style={{ maxWidth: "900px" }}>
        <div className="mb-4">
          <h5 className="text-uppercase fw-bold mb-1 text-start">
            <span>Time of day</span>
            <ToggleButtons />
          </h5>
          <p
            className="text-uppercase mb-0 text-start fw-bold fs-12 py-3"
            style={{ color: "#6c757d" }}
          >
            % of uses
          </p>
          <div className="col-12">
            <Chart type="line" data={data} options={options} />
          </div>
          <ChartDivider />
        </div>
      </div>
    </>
  );
};

export default TimeOfDay;
