export const totalAverageOptionsReports = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
  },
  barThickness: 300,
};
