import React from "react";
import { Dropdown } from "primereact/dropdown";
import { Chart } from "primereact/chart";
import ChartSkeleton from "../../../components/Skeletons/ChartSkeleton";
import TableTdSkeleton from "../../../components/Skeletons/TableTdSkeleton";
import { totalAverageOptionsReports } from "../../../utils/chartOptions/reports/reports";
const TotalAverage = React.memo(function TotalAverage(props) {
  const options = [
    { label: "Use", value: "use" },
    { label: "Day", value: "day" },
  ];

  const how_Often_Options = [
    { label: "Day", value: "day" },
    { label: "Week", value: "week" },
  ];

  return (
    <>
      <div className="fs-14 text-start mt-4 mb-4">
        <strong>
          # OF USES PER{" "}
          <Dropdown
            value={props.selectedOption}
            options={
              props.activeButton === "how_often" ? how_Often_Options : options
            }
            onChange={(e) => props.setSelectedOption(e.value)}
            style={{
              width: "auto",
              display: "inline-flex",
              color: "#002133",
              padding: "0",
            }}
            className="form-control fw-600"
            id="hmSelect"
          />{" "}
          - TOTAL AVERAGE
        </strong>
      </div>
      <div className="col-12">
        {props.totalAverageGraph?.datasets?.length > 0 ? (
          <Chart
            type="bar"
            data={props.totalAverageGraph}
            options={totalAverageOptionsReports}
          />
        ) : (
          <ChartSkeleton />
        )}
        <div className="my-table data-table">
          <div className="table-responsive">
            <table className="table text-start">
              <thead>
                <tr className="my-table table-first-row-border">
                  <th></th>
                  <th className="text-center" style={{ color: "#B1B1B1" }}>
                    # OF USES PER DAY - TOTAL AVERAGE
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong style={{ color: "#949494" }}>AVERAGE</strong>
                  </td>
                  {props.totalAverageGraph?.datasets?.[0]?.data?.length > 0 ? (
                    props.totalAverageGraph.datasets[0].data.map(
                      (data, index) => (
                        <td
                          className="text-center"
                          key={index}
                          style={{ color: "#949494" }}
                        >
                          {data}
                        </td>
                      ),
                    )
                  ) : (
                    <TableTdSkeleton />
                  )}
                </tr>
                <tr>
                  <td>
                    <strong style={{ color: "#949494" }}>
                      # of Participants
                    </strong>
                  </td>
                  {props.totalAverageGraph?.datasets?.[0]?.participant?.length >
                  0 ? (
                    props.totalAverageGraph.datasets[0].participant.map(
                      (participant, index) => (
                        <td
                          className="text-center"
                          key={index}
                          style={{ color: "#949494" }}
                        >
                          {participant}
                        </td>
                      ),
                    )
                  ) : (
                    <TableTdSkeleton />
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
});

export default TotalAverage;
