import React from "react";
import { Skeleton } from "primereact/skeleton";

const ChartSkeleton = () => {
  return (
    <div className="skeleton-chart">
      <Skeleton width="100%" height="300px" />
    </div>
  );
};

export default ChartSkeleton;
